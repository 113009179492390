import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { setDefaultMeta } from '../actions/meta';



// Components
import FontIcon from '../components/ui/FontIcon';
import LoadingElement from '../components/ui/LoadingElement';
import Meta from '../components/ui/Meta';


// Routers
import { listNVByRut, listOneNV } from '../services/SelfManagement/getNV.service';
import { ResultsTableRut } from '../components/self-management/Table';
import DetailedNV from '../components/self-management/DetailedNV';
import { startLogoutOffice } from '../actions/auth';
import { ModalExit } from '../components/self-management/ModalExit';


const CONSTANT = {
  urlDownloadPDF: (nv, patente, comuna, sucursalId) => {
    if (!comuna.length) {
      return nv
        ? process.env.REACT_APP_API_URL + `/api/v1/notas-venta/${nv}/pdf?sucursal_id=${sucursalId}&patente=${patente}`
        : ''
    }

    else {
      return nv
        ? process.env.REACT_APP_API_URL + `/api/v1/notas-venta/${nv}/pdf?sucursal_id=${sucursalId}&patente=${patente}&comuna=${comuna}`
        : ''
    }
  },
  search: {
    rut: '',
    nota_venta: '',
    pedido: '',
    searchType: ''
  },
  printForm: { patente: '', comuna: '', comunas: [], region: '', regiones: [] },

  firstIcon: `${process.env.PUBLIC_URL}/assets/images/self-management/icon_1.png`,
  secondIcon: `${process.env.PUBLIC_URL}/assets/images/self-management/icon_2.png`,
  thirdIcon: `${process.env.PUBLIC_URL}/assets/images/self-management/icon_3.png`,
}

const conditionsPrintButton = (form) => {
  const { comuna, comuna_requerida, patente, nv } = form
  if (!nv) return true
  if (comuna_requerida && (!patente?.length || !comuna?.length)) return true
  if (!comuna_requerida && !patente.length) return true
  return false
}

const SelectDetailComponent = ({ searchType, data, handleValue = () => null, selectedNV }) => {
  if (!searchType?.length) return (<></>)

  switch (searchType) {
    case 'rut':
      return (<ResultsTableRut data={data} handleValue={handleValue} selectedNV={selectedNV} />)

    default:
      return (<DetailedNV data={data} />)
  }
}

export const SelfManagement = () => {

  const dispatch = useDispatch();
  const { office_id } = useSelector(state => state.auth)
  const [search, setSearch] = useState(CONSTANT.search)
  const [selectedNV, setSelectedNV] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [printForm, setPrintForm] = useState(CONSTANT.printForm)
  const [loadingPatente, setLoadingPatente] = useState(false)
  const [modalExit, setModalExit] = useState({ data: null, display: false })

  const resetInitialValues = () => {
    setSelectedNV(null)
    setData(null)
    setPrintForm(CONSTANT.printForm)
    setSearch(CONSTANT.search)

    setLoading(false)
  }

  useEffect(() => {
    document.title = 'Chileneumaticos | Autoservicio';
  }, []);

  useEffect(() => {
    dispatch(setDefaultMeta());
  }, [dispatch]);

  useEffect(() => {
    if (!printForm.region) {
      setPrintForm(prev => ({ ...prev, filteredComunas: prev.comunas }))
      return
    }

    setPrintForm(prev => ({
      ...prev,
      filteredComunas: prev.comunas.filter(({ region_id }) => Number(region_id) === Number(printForm.region))
    }))

  }, [printForm.region])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'rut') return setSearch(({ rut: value, nota_venta: '', pedido: '' }))
    if (name === 'nota_venta') return setSearch(({ nota_venta: value, rut: '', pedido: '' }))
    if (name === 'pedido') return setSearch(({ pedido: value, nota_venta: '', rut: '' }))

    if (name === 'patente') return setPrintForm(prev => ({ ...prev, [name]: value }))
  };

  const handleSubmitRut = async (e, searchType) => {
    e.preventDefault();

    setSearch(prev => ({ ...prev, searchType }))
    setLoading(true)
    setPrintForm(CONSTANT.printForm)

    if (searchType === 'rut') {
      await listNVByRut(search)
        .then((res) => {
          setData(res?.data)
        })
        .finally(() => {
          setLoading(false)
        })
      return
    }
  }

  const handleFetch = async (e, searchType) => {
    e.preventDefault();

    setSearch(prev => ({ ...prev, searchType }))
    setLoading(true)
    setPrintForm(CONSTANT.printForm)

    await listOneNV(search)
      .then((res) => {
        if (res?.data?.nv?.ORDER_NUM) {
          setData(res?.data)
          handleSelectNV({ nota_venta: res?.data?.nv?.ORDER_NUM, i: 0 })
        }
        else {
          setData(null)
          handleSelectNV(null)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false)
      })
    return
  }

  const handlePrint = (e) => {
    e.preventDefault();

    try {
      if (!printForm?.patente) throw new Error('Falta la patente');

      window.open(CONSTANT.urlDownloadPDF(selectedNV?.nota_venta, printForm?.patente, printForm?.comuna, office_id), '_blank')
    } catch (err) {
      return console.log(err);
    }
  }

  const handleSelectNV = async (obj) => {
    if (obj?.nota_venta === selectedNV?.nota_venta) return

    setSelectedNV(obj)
    setLoadingPatente(true)
    setPrintForm(CONSTANT.printForm)

    await listOneNV({ nota_venta: obj?.nota_venta })
      .then((res) => {
        if (res?.data?.nv?.ORDER_NUM) {
          setPrintForm((prev) => ({ ...prev, ...res?.data, filteredComunas: res?.data?.comunas }))
        }
      })
      .finally(() => {
        setLoadingPatente(false)
      })
    return
  }

  const handleSelectComuna = (e) => {
    const { value } = e.target

    setPrintForm(prev => ({ ...prev, comuna: value }))
  }

  const handleSelectRegion = (e) => {
    const { value } = e.target

    setPrintForm(prev => ({ ...prev, region: value }))
  }

  const handleModalExit = (e) => {
    e.preventDefault();

    setModalExit(prev => ({ data: data?.autoservicio, display: !prev.display }))
  }

  return (
    <>
      <Meta />

      <div className='self-management'>
        <figure className='self-management-banner'>
          <img className='logo' src={`${process.env.PUBLIC_URL}/assets/images/logo-oscuro.svg`} alt='Logo chileneumáticos' />

          <h1 className='title'>AUTOSERVICIO</h1>

          <button onClick={() => dispatch(startLogoutOffice())}>
            <h3 className='title logout'>
              <FontIcon className={'icon'} icon={'faX'} />

              Cerrar sesión
            </h3>
          </button>
        </figure>

        <h5 className='subtitle'>PASOS A SEGUIR</h5>

        <div className='steps'>

          <figure className='step'>
            <img src={CONSTANT.firstIcon} alt='Ícono de ingresa tus datos de reserva' />

            <figcaption>
              <h4>1</h4>

              <h5>INGRESA TUS DATOS DE RESERVA</h5>

              <p>Digita el RUT o número de orden de servicio</p>
            </figcaption>
          </figure>

          <figure className='step'>
            <img src={CONSTANT.secondIcon} alt='Ícono de verifica tu orden' />

            <figcaption>
              <h4>2</h4>

              <h5>VERIFICA TU ORDEN Y COMPLETA TUS DATOS</h5>

              <p>Verifica los datos de tu reserva e ingresa la patente de tu auto y la comuna desde donde nos visitas</p>
            </figcaption>

          </figure>

          <figure className='step'>
            <img src={CONSTANT.thirdIcon} alt='Ícono de imprime tu orden' />

            <figcaption>
              <h4>3</h4>

              <h5>IMPRIME TU ORDEN Y DIRÍGETE AL MÓDULO DE RECEPCIÓN</h5>

              <p>Retira los comprobantes de tu orden y dirígete a nuestros técnicos para iniciar los servicios a tu auto</p>
            </figcaption>
          </figure>
        </div>

        <h3 className='mt-section-desk self-management-subtitle'>Buscar por:
          <button className={`self-management-reset-values ${data || search.nota_venta || search.rut || search.pedido ? '' : 'hidden'}`} onClick={resetInitialValues}>Limpiar todo
            <FontIcon icon='faX' />
          </button>
        </h3>

        <form
          className='self-management-form'
        >
          <div className='self-management-inputs-container'>
            <label>
              RUT:
              <input
                type='text'
                placeholder='Ingrese un RUT'
                value={search?.rut ?? ''}
                onChange={handleChange}
                name='rut'
              />

              <button
                type='submit'
                onClick={(e) => handleSubmitRut(e, 'rut')}
                disabled={!search?.rut?.length}
              >
                <FontIcon icon='faSearch' />

                <p>Buscar</p>
              </button>
            </label>

            <label>
              PEDIDO:
              <input
                type='text'
                placeholder='Ingrese el número de pedido'
                value={search?.pedido ?? ''}
                onChange={handleChange}
                name='pedido'
              />

              <button
                type='submit'
                onClick={(e) => handleFetch(e, 'pedido')}
                disabled={!search?.pedido?.length}
              >
                <FontIcon icon='faSearch' />

                <p>Buscar</p>
              </button>
            </label>

            <label>
              NOTA DE VENTA:
              <input
                type='text'
                placeholder='Ingrese el número nota de venta'
                value={search?.nota_venta ?? ''}
                onChange={handleChange}
                name='nota_venta'
              />

              <button
                onClick={(e) => handleFetch(e, 'nota_venta')}
                type='submit'
                disabled={!search?.nota_venta?.length}
              >
                <FontIcon icon='faSearch' />

                <p>Buscar</p>
              </button>
            </label>
          </div>
        </form>


        {loading
          ? <LoadingElement className='tracking-spinner' />

          : <SelectDetailComponent searchType={search?.searchType} data={data} handleValue={handleSelectNV} selectedNV={selectedNV} />
        }

        {selectedNV &&
          <form
            className='self-management-form-bottom'
          >
            {loadingPatente
              ? <div className='self-management-loading-patente'>
                <span >Procesando su solicitud, un momento...</span>
              </div>

              : <>
                <div
                  className='self-management-inputs-container'
                >
                  <label className={`${printForm?.patente?.length ? '' : 'missing-field'}`}>
                    PATENTE:
                    <input
                      type='text'
                      placeholder='Ingrese una patente'
                      value={printForm?.patente ?? ''}
                      onChange={handleChange}
                      name='patente'
                    />
                  </label>

                  {printForm?.comuna_requerida &&
                    <>
                      <label className={`self-management-select-comuna ${printForm?.comuna?.length ? '' : 'missing-field'}`}>
                        REGIÓN:
                        <select onChange={handleSelectRegion} >
                          <option value=''>Seleccione una región</option>
                          {printForm?.regiones?.map(({ id, nombre }, i) => (
                            <option key={`${i}-${nombre}`} value={id}>{nombre}</option>
                          ))}
                        </select>
                      </label>

                      <label className={`self-management-select-comuna ${printForm?.comuna?.length ? '' : 'missing-field'}`}>
                        COMUNA:
                        <select onChange={handleSelectComuna} disabled={!printForm.region}>
                          <option value=''>Seleccione una comuna</option>
                          {printForm?.filteredComunas?.map(({ id, nombre }, i) => (
                            <option key={`${i}-${nombre}`} value={id}>{nombre}</option>
                          ))}
                        </select>
                      </label>
                    </>
                  }
                </div>

                <button
                  disabled={conditionsPrintButton(printForm)}
                  className='print-pdf-btn'
                  onClick={handlePrint}
                  target='_blank'
                  rel='noreferrer'
                >
                  Imprimir
                </button>

                <button
                  disabled={!data?.autoservicio}
                  className='print-pdf-btn'
                  onClick={handleModalExit}
                  target='_blank'
                  rel='noreferrer'
                >
                  Salida
                </button>
              </>
            }
          </form>
        }
      </div>

      <ModalExit modalData={modalExit} handleClose={handleModalExit} />
    </>
  );
}


