import React from 'react';
import PropTypes from 'prop-types';



const AuthInput = ({value, handleValue, type, name, placeholder, error, validate}) => {
   
   const handleErrorClass = (error) => error ? 'auth-form-group error' : 'auth-form-group';

   const handleChange = (e) => {
      handleValue(e);
      validate(e.target.value);
   }

   return (
      <div className={handleErrorClass(error)}>
         <input
            type={type}
            placeholder={placeholder}
            autoComplete='off'
            name={name}
            value={value}
            onChange={handleChange}
         />

         { error && (<p className='login-error'>{error}</p>) }
      </div>
   );
}



AuthInput.propTypes = {
   value: PropTypes.string.isRequired,
   handleValue: PropTypes.func.isRequired,
   type: PropTypes.oneOf(['text', 'password', 'email']),
   name: PropTypes.string.isRequired,
   placeholder: PropTypes.string.isRequired,
   error: PropTypes.string,
   validate: PropTypes.func
}

AuthInput.defaultProps = {
   type: 'text',
   error: null,
   validate: () => null
}



export default AuthInput;