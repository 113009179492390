import { stringCapitalize } from '../helpers/format'

export const types = {
   SET_ACTIVE: '[CHECKOUT] set active step',
   SET_LOADING: '[CHECKOUT] set loading state',
   SET_VALUE: '[CHECKOUT] set value',
   SET_ERROR: '[CHECKOUT] set error',

   // Cargar la información de un usuario según su email
   SET_AUTH_BY_EMAIL: '[CHECKOUT] set found user by email state',

   // Cargar la información de los tipos de facturación
   SET_METHODS: '[CHECKOUT] set methods',

   // Cargar la información de las comunas,
   SET_COMMUNES_EMP: '[CHECKOUT] set communes for billing',

   // Cargar la información de las locaciones
   SET_LIST: '[CHECKOUT] set list by key',
   SET_REGIONS: '[CHECKOUT] get regions',
   SET_COMMUNES: '[CHECKOUT] get communes',
   SET_PLACES: '[CHECKOUT] get places',
   SET_ADDITIONALS: '[CHECKOUT] get additionals data',
   SET_CALENDAR_SCHEDULE: '[CHECKOUT] get calendar schedule',

   // Controlar estado del modal
   SET_CALENDAR_MODAL: '[CHECKOUT] set calendar modal state',

   // Cargar la información de los métodos de pago
   SET_PAYMENTS: '[CHECKOUT] set payments',

   // Cargar la información de los tipos de pago
   SET_PAYMENTS_TYPES: '[CHECKOUT] set payments types',

   // Limpiar los campos de la vista al salir de ella
   RESET_FIELDS: '[CHECKOUT] reset view fields'
}



const initialState = {
   // Steps
   steps: ['Datos del cliente', 'Datos de entrega', 'Método de pago'],
   activeStep: 1,
   loading: true,
   // Step 1
   rut: '',
   name: '',
   email: '',
   phone: '',
   country: '',
   method: '',
   razonSocial: '',
   rutEmp: '',
   communeEmp: '',
   addressEmp: '',
   giro: '',
   comments: '',

   foundState: false,
   // Step 2
   region: '',
   commune: '',
   place: '',
   address: '',
   // Step 3
   payment: '',
   paymentType: '',

   // Errors
   errors: {
      // Step 1
      rut: null,
      name: null,
      email: null,
      phone: null,
      country: null,
      method: null,
      razonSocial: null,
      rutEmp: null,
      communeEmp: null,
      addressEmp: null,
      giro: null,
      // Step 2
      region: null,
      commune: null,
      place: null,
      address: null,
      // Step 3
      payment: null,
      productsStock: [],
      paymentType: null,
   },

   // Opciones de facturación (step 1)
   methods: [],
   communesEmp: [],

   // Opciones de locaciones (step 2)
   regions: [],
   communes: [],
   communesList: [],
   places: [],
   placesList: [],
   additionals: {
      installation: null,
      fee: null,
      date: null,
      freeInstallationAt: null,
      isNotFreeInstallation: null
   },
   schedule: [],

   calendarOpen: false,

   // Opciones de pagos (step 3)
   payments: [],
   paymentsType: [],
}



export const checkoutReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_ACTIVE:
         return {
            ...state,
            activeStep: action.payload
         }

      case types.SET_VALUE:
         return {
            ...state,
            [action.payload.key]: action.payload.value
         }

      case types.SET_ERROR:
         return {
            ...state,
            errors: {
               ...state.errors,
               [action.payload.key]: action.payload.error
            }
         }

      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_AUTH_BY_EMAIL:
         return {
            ...state,
            foundState: action.payload
         }

      case types.SET_METHODS:
         return {
            ...state,
            methods: action.payload
         }

      case types.SET_COMMUNES_EMP:
         return {
            ...state,
            communesEmp: action.payload
         }

      case types.SET_LIST:
         return {
            ...state,
            [action.payload.key + 'List']: action.payload.list
         }

      case types.SET_REGIONS:
         return {
            ...state,
            regions: action.payload
         }

      case types.SET_COMMUNES:
         return {
            ...state,
            communes: action.payload
         }

      case types.SET_PLACES:
         return {
            ...state,
            places: action.payload
         }

      case types.SET_ADDITIONALS:
         return {
            ...state,
            additionals: {
               installation: action.payload.installation,
               fee: action.payload.fee,
               date: action.payload.date,
               freeInstallationAt: action.payload.freeInstallationAt,
               isNotFreeInstallation: action.payload.isNotFreeInstallation,
               dispatchDays: action.payload.dispatchDays,
            }
         }

      case types.SET_CALENDAR_SCHEDULE:
         return {
            ...state,
            schedule: action.payload
         }

      case types.SET_CALENDAR_MODAL:
         return {
            ...state,
            calendarOpen: action.payload
         }

      case types.SET_PAYMENTS:
         return {
            ...state,
            payments: action.payload
         }

      case types.SET_PAYMENTS_TYPES:
         return {
            ...state,
            paymentsType: action.payload
         }

      case types.RESET_FIELDS:
         return initialState;

      default:
         return state;
   }
}