import { useState } from "react"
import Modal from "./Modal"
import AuthInput from "../auth/AuthInput"

const isTesting = process.env.REACT_APP_API_URL === 'https://api.prodeim.com'
const testPass = process.env.REACT_APP_TEST_PASS

const TestingModal = () => {
  const [modal, setModal] = useState({ display: isTesting, pass: '' })
  const [errors, setErrors] = useState([])

  const handleChange = (e) => {
    const { value } = e.target

    setModal(prev => ({ ...prev, pass: value }))
  }

  const handleClose = (e) => {
    e.preventDefault()
    setErrors([])

    if (testPass === modal.pass) setModal({ display: false, pass: '' })

    setErrors(['La clave de acceso no es correcta'])
  }

  return (
    <Modal state={modal.display}>
      <div className='auth-test-modal'>
        <div className="auth-test-modal-title">
          <h3>CHILENEUMÁTICOS</h3>

          <h5>AMBIENTE DE PRUEBAS</h5>
        </div>

        <form className="auth-test-modal-form" onSubmit={handleClose}>
          <label>
            Clave de acceso
            <AuthInput handleValue={handleChange} error={errors} />
          </label>

          <button className="auth-test-modal-submit" type='submit'>Entrar</button>
        </form>

        <span className="auth-test-modal-footer">Este es un ambiente de pruebas, <a href="https://chileneumaticos.cl" rel="noreferrer">clic aquí</a> si desea ir a la tienda</span>
      </div>

    </Modal>
  )
}

export default TestingModal