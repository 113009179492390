import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';



// Components
import FontIcon from './FontIcon';



// Helpers
import { analyticsPhoneCall, analyticsWhatsapp } from '../../helpers/google-analytics';
import { contactEmail, phoneNumber, phoneNumberShow, whatsapp, whatsappLink } from '../../helpers/contact';
import { pixelContact } from '../../helpers/meta-pixel';



const Footer = () => {

   const { navigation } = useSelector(state => state.ui);

   const trackingContact = () => {
      analyticsPhoneCall();
      pixelContact('Teléfono', phoneNumber);
   }

   const trackingWhatsapp = () => {
      analyticsWhatsapp();
      pixelContact('Whatsapp', `+${whatsapp}`);
   }

   return (
      <section className='footer-section'>
         <div className='footer-container'>
            <div className='footer-content footer'>
               <div className='footer-col'>
                  {
                     navigation.col1.map(nav => (
                        <FooterLink
                           key={'footer-nav-' + nav.id}
                           uri={nav.uri}
                           text={nav.text}
                           extern={nav.extern}
                        />
                     ))
                  }
               </div>

               <div className='footer-col'>
                  {
                     navigation.col2.map(nav => (
                        <FooterLink
                           key={'footer-nav-' + nav.id}
                           uri={nav.uri}
                           text={nav.text}
                           extern={nav.extern}
                        />
                     ))
                  }
               </div>

               <div className='footer-col'>
                  {
                     navigation.col3.map(nav => (
                        <FooterLink
                           key={'footer-nav-' + nav.id}
                           uri={nav.uri}
                           text={nav.text}
                           extern={nav.extern}
                        />
                     ))
                  }
               </div>

               <div className='footer-col footer-brand'>
                  <div className='footer-contact'>
                     <a
                        href={`tel:${phoneNumber}`}
                        className='footer-contact-row'
                        onMouseDown={trackingContact}
                     >
                        <FontIcon icon='faMobileScreenButton' />

                        <p>{phoneNumberShow}</p>
                     </a>

                     <a
                        href='https://goo.gl/maps/FHdMka9p3vgATmZ17?coh=178572&entry=tt'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-contact-row'
                     >
                        <FontIcon icon='faLocationDot' />

                        <p>Av. Irarrazaval 1061. Ñuñoa, Santiago.</p>
                     </a>

                     <a
                        href='https://maps.app.goo.gl/MDATY8C3d3UYcuGq6'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-contact-row'
                     >
                        <FontIcon icon='faLocationDot' />

                        <p>Av. Francisco Bilbao 5845, La Reina</p>
                     </a>

                     <a
                        href={`mailto:${contactEmail}`}
                        target='_blank'
                        rel='noreferrer'
                        className='footer-contact-row'
                     >
                        <FontIcon icon='faEnvelope' />

                        <p>{contactEmail}</p>
                     </a>
                  </div>

                  <div className='footer-social'>
                     <a
                        href='https://www.facebook.com/Chileneumaticos'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                     >
                        <FontIcon path='Fab' icon='faFacebookF' />
                     </a>

                     <a
                        href='https://www.instagram.com/chileneumaticos'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                     >
                        <FontIcon path='Fab' icon='faInstagram' />
                     </a>

                     <a
                        href='https://www.tiktok.com/@chileneumaticos'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                     >
                        <FontIcon path='Fab' icon='faTiktok' />
                     </a>

                     <a
                        href='https://twitter.com/chileneumaticos'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                     >
                        <FontIcon path='Fab' icon='faTwitter' />
                     </a>

                     <a
                        href='https://www.linkedin.com/company/chileneumaticos'
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                     >
                        <FontIcon path='Fab' icon='faLinkedin' />
                     </a>

                     <a
                        href={whatsappLink}
                        target='_blank'
                        rel='noreferrer'
                        className='footer-social-element'
                        onMouseDown={trackingWhatsapp}
                     >
                        <FontIcon path='Fab' icon='faWhatsapp' />
                     </a>
                  </div>

                  <div className='footer-trust'>
                     <div className='footer-trust-image'>
                        <img
                           src={process.env.PUBLIC_URL + '/assets/images/ccs.png'}
                           alt='ccs logo'
                        />
                     </div>

                     <div className='footer-trust-body'>
                        <p>Contamos con el respaldo de</p>

                        <span>Comercio Ético de la Cámara de Comercio de Santiago</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className='footer-copy-container'>
            <div className='footer-content footer-copy'>
               <Link
                  to='/'
                  className='footer-copy-brand'
               >
                  <img
                     src={process.env.PUBLIC_URL + '/assets/images/logo-blanco.svg'}
                     alt='footer brand logo'
                  />
               </Link>

               <p>&copy; Todos los derechos reservados.</p>

               <p>Este sitio web ha sido desarrollado para ser compatible con las últimas versiones de los navegadores.</p>
            </div>
         </div>
      </section>
   );
}

const FooterLink = ({ uri, text, extern }) => {

   const handleActive = ({ isActive }) => isActive ? 'footer-link active' : 'footer-link';

   if (!extern) {
      return (
         <NavLink
            to={uri}
            className={handleActive}
         >{text}</NavLink>
      );
   }

   return (
      <a
         target='_blank'
         rel='noopener noreferrer'
         href={uri}
         className='footer-link'
      >{text}</a>
   );
}



export default Footer;