import { request } from "../../helpers"

export const sendSalida = async (nv, data) => {
  try {
    const response = await request({
      path: `/notas-venta/${nv}`,
      method: 'POST',
      body: data,
      // Nota: se debe enviar como asJson: false porque sino tira error
      asJson: false,
    })
      .then(res => res)
      .catch(err => {
        throw err
      })

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}