import React, { useEffect, useRef, useState } from 'react'
import Modal from '../ui/Modal'
import { sendSalida } from '../../services/SelfManagement/sendSalida.service'
import moment from 'moment'

export const ModalExit = ({ modalData, handleClose }) => {
  const inputDni = useRef(null)
  const [dni, setDni] = useState('')

  useEffect(() => {
    if (!inputDni) return

    inputDni?.current?.focus()
  }, [inputDni, modalData])


  const handleInputChange = (e) => {
    setDni(e.target.value)
  }


  const handleSumbit = async (e) => {
    e.preventDefault();

    await sendSalida(modalData?.nota_venta, { datos_dni: dni })
      .then(res => res)
      .catch(err => console.error(err))
  }


  return (
    <Modal state={modalData.display} close={handleClose}>
      <div className='self-management-modal-exit-container'>
        <button className='btn-close' onClick={handleClose}>X</button>

        <div className='content'>
          <h3 className='title'>SALIDA</h3>

          {!modalData?.fecha_salida
            ?
            <form className='exit-form' onSubmit={handleSumbit}>
              <label className='code-input'>
                Datos DNI:

                <input autoFocus ref={inputDni} value={dni} onChange={handleInputChange} />
              </label>

              <button className='btn-submit' type='submit' disabled={!dni}>Enviar</button>
            </form>

            :
            <div className='exit-form'>
              <p>DNI: <b>{modalData?.datos_dni ?? 'Error'}</b></p>
              <p>Fecha salida: <b>{modalData?.fecha_salida ? moment(modalData?.fecha_salida).format('DD-MM-YYYY') : 'Error'}</b></p>
            </div>
          }
        </div>
      </div>
    </Modal>
  )
}
